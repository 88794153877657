import { AdyenAuthAction } from 'types/adyen'
import { PaymentAuthActionDto } from 'types/dtos/payment'
import { PaymentAuthActionModel } from 'types/models/payment'

const isNestedActionResponse = (
  response?: AdyenAuthAction | { action: AdyenAuthAction },
): response is { action: AdyenAuthAction } => !!response && 'action' in response

export function transformPaymentAuthAction({
  adyen,
  adyen_bank,
}: PaymentAuthActionDto): PaymentAuthActionModel {
  return {
    adyen: isNestedActionResponse(adyen) ? adyen.action : adyen,
    adyenBank: isNestedActionResponse(adyen_bank) ? adyen_bank.action : adyen_bank,
  }
}
